import { Notifier } from '@airbrake/browser';

const USE_AIR_BRAKE = process.env.USE_AIRBRAKE !== 'false';

class AirBrakeNotifier {
  airbrake;

  constructor() {
    this.airbrake = new Notifier({
      projectId: 254300,
      projectKey: '7f4296691831056d5b51ec5ed49d1a0c',
      environment: process.env.RAILS_ENV || 'production',
      instrumentation: {
        onerror: false,
      },
    });

    this.airbrake.addFilter((notice) => {
      if (notice.errors[0].message === 'Extension context invalidated.') {
        return null;
      }
      return notice;
    });
  }
}

export { AirBrakeNotifier, USE_AIR_BRAKE };
